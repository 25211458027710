<template>
  <div class="group-members">
    <div
      v-if="!userGroupMembersLoading"
      class="d-flex justify-content-end button-style mt-n3 mb-1"
    >
      <span class="font-weight-bolder">Members : {{
        groupDetails && groupDetails.user_group_has_members && groupDetails.user_group_has_members.length ? groupDetails.user_group_has_members.length - 1 : null
      }} / {{ groupDetails.quota }}</span>
    </div>
    <b-card
      no-body
    >
      <b-skeleton-table
        v-if="userGroupMembersLoading"
        :rows="5"
        :columns="5"
        :table-props="{ bordered: true, striped: true, tableClass:'skeleton-loading-table' }"
      />
      <b-table
        v-else
        id="allMembers-table"
        hover
        selectable
        :select-mode="selectMode"
        :items="groupDetails.user_group_has_members"
        responsive
        sticky-header="true"
        :fields="tableColumns"
        show-empty
        empty-text="No matching records found"
        class="position-relative"
      >
        <!-- Column: User -->
        <template #cell(NAME)="data">
          <b-media vertical-align="center">
            <span
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.user.name }}
            </span>
          </b-media>
        </template>

        <!-- Column: Email -->
        <template #cell(EMAIL)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.user.image?data.item.user.image:require('@/assets/images/avatars/User-place-holder.svg')"
              />
            </template>
            <span class="text-nowrap">
              {{ data.item.user.email }}
            </span>
          </b-media>
        </template>

        <!-- Column: Status -->
        <template #cell(INVITATION_STATUS)="data">
          <b-badge
            pill
            :variant="`${resolveUserStatusVariant(data.item.invitation_status)}`"
            class="badge-class d-flex align-items-center"
          />
          <span
            class="text-capitalize"
          >
            {{ memberStatusTextFun(data.item.invitation_status) }}
          </span>
        </template>
        <!-- Column: Role -->
        <template #cell(ROLE)="data">
          <span class="text-nowrap">
            {{ roleStatusTextFun(data.item.role) }}
          </span>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span
              class="text-muted"
            /></b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BMedia,
  VBToggle,
  BBadge,
  BSkeletonTable,
  VBTooltip, BAvatar,

} from 'bootstrap-vue'
import Vue from 'vue'
import Ripple from 'vue-ripple-directive'
import Vuesax from 'vuesax'
import 'vuesax/dist/vuesax.css'
import MemberType from '@/common/enums/memberTypeEnum'
import MemberPermissions from '@/common/enums/memberPermissionsEnum'
import InvitationStatusType from '@/common/enums/invitationStatusEnum'

Vue.use(Vuesax)
Vue.directive('ripple', Ripple)
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BMedia,
    BBadge,
    BSkeletonTable,
    BAvatar,
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-toggle': VBToggle,
    Ripple,
  },
  data() {
    return {
      MemberType,
      MemberPermissions,
      InvitationStatusType,
      userGroupMembersLoading: false,
      groupDetails: [],
      updateMember: {
        id: null,
        permissions: [],
        role: null,
      },
      tableColumns: [
        { key: 'NAME' },
        { key: 'EMAIL' },
        { key: 'INVITATION_STATUS' },
        { key: 'ROLE' },
      ],
      selectMode: 'single',
      selected: [],
      selectedGroupId: null,
    }
  },
  async mounted() {
    this.selectedGroupId = this.$route.query.groupId
    await this.getAllMembersOfGroup()
  },
  methods: {
    async getAllMembersOfGroup() {
      this.userGroupMembersLoading = true
      try {
        const res = await this.$axios.get(`admin/user-has-group/get-user-group-details/${this.$route.params.userId}`)
        const data = res.data.data || {}
        this.groupDetails = data || {}
        this.userGroupMembersLoading = false
      } catch ({
        response: {
          data: {
            statusCode, message,
          },
        },
      }) {
        this.$swal(message.toString())
        this.userGroupMembersLoading = false
      }
      this.userGroupMembersLoading = false
    },
    resolveUserStatusVariant(Status) {
      if (Status === InvitationStatusType.PENDING) return 'secondary'
      if (Status === InvitationStatusType.APPROVED) return 'success'
      return 'warning'
    },
    memberStatusTextFun(Status) {
      if (Status === InvitationStatusType.PENDING) return 'Pending'
      if (Status === InvitationStatusType.APPROVED) return 'Approved'
      return 'Rejected'
    },
    roleStatusTextFun(Status) {
      if (Status === MemberType.OWNER) return 'Owner'
      if (Status === MemberType.MEMBER) return 'Member'
      return 'Super Member'
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.group-members {
  body {
    line-height: 0 !important;
  }
  .dropdown-item{
    padding-bottom: 5px !important;
  }
  .dropdown-menu{
    padding: 0px !important;
  }
  .per-page-selector {
    width: 90px;
  }
  .b-table-sticky-header{
    overflow-y: auto;
    //min-height: 120px !important;
    max-height: 600px !important;
  }
  .invoice-filter-select {
    min-width: 190px;

    ::v-deep .vs__selected-options {
      flex-wrap: nowrap;
    }

    ::v-deep .vs__selected {
      width: 100px;
    }
  }
  .badge:empty{
    display: inline !important;
  }
  .badge{
    padding: 0 !important;
  }
  .badge-pill{
    padding-right: 0.3rem !important;
    padding-left: 0.3rem !important;
  }
  .badge-class{
    height: 5px !important;
    width: 5px !important;
    font-size: 6px !important;
    margin-right: 4px;
  }
  .button-style{
    padding-right: 2rem;
  }
  .loged-user-tag-class{
    background-color: rgba(40, 199, 111, 0.22);
    color: #28c76f;
    border-radius: 25px;
    padding: 5px 5px;
  }
}
</style>
